import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Tutorials";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tutorials",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tutorials",
        "aria-label": "tutorials permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tutorials`}</h1>
    <p>{`Welcome to our Armeria tutorials!
Learn how to write a service with Armeria by walking through these tutorials.
These tutorials take you through steps based on a sample service, a blog service, to offer you practical guidance.`}</p>
    <p>{`We've prepared three different tutorials for you:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/rest/blog"
        }}>{`REST tutorial`}</a>{`: Learn how to build a REST service with Armeria.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/grpc/blog"
        }}>{`gRPC tutorial`}</a>{`: Learn how to build a `}<a parentName="li" {...{
          "href": "https://grpc.io/"
        }}>{`gRPC`}</a>{` service with Armeria.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog"
        }}>{`Thrift tutorial`}</a>{`: Learn how to build a service using `}<a parentName="li" {...{
          "href": "https://thrift.apache.org/"
        }}>{`Apache Thrift`}</a>{` with Armeria.`}</li>
    </ul>
    <p>{`You can choose any tutorial that interests you.
Each one is designed to be clear and simple, so you'll be able to build your own service with Armeria by the time you're done.`}</p>
    <p>{`Let's get started!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      